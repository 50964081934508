import React, { useState, useEffect } from "react"
import Layout from "./../components/Layout"
import CgvCgu from "./../nav/CGV-CGU"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"

const CGUPage = () => {
  const [user, setUser] = useState(undefined)
  useEffect(() => {
    getUser(setUser)
  }, [])

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Conditions Générales de Vente et d’Utilisation</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-25 ans. Sur cette page,
          tu trouveras les conditions générales de vente et d'utilisation de WYNIT."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,conditions générales d'utilisation,conditions générales de vente"
        />
        <link
          rel="canonical"
          href="https://wynit.com/conditions-generales-d-utilisation"
        />
      </Helmet>
      <CgvCgu />
    </Layout>
  )
}

export default CGUPage
