import React from "react"
import { Wrapper, SectionUnderline } from "./CGVCGU.module.css"
import { OrangeTitle } from "./../../css/Titre.module.css"
import {
  PaddingLeft30,
  PaddingTop10,
  PaddingTop20,
  PaddingTop30,
  PaddingTop50,
} from "./../../css/Padding.module.css"
import { classNames } from "./../../helpers/classNames"

const CGVCGU = () => {
  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>
        Conditions Générales de Vente et d’Utilisation
      </h1>
      <div className={PaddingTop50}>
        <h3>PRÉAMBULE</h3>
        <p className={PaddingTop30}>
          La société WYNIT a pour objet de commercialiser, par l’intermédiaire
          du site et de l’application « wynit », auprès de jeunes âgés de 15 ans
          et jusqu’à 26 ans des cartes virtuelles sous forme d’abonnement leur
          permettant de bénéficier d’offres de réduction et d’offres
          promotionnelles auprès de différents établissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Le site et l’application « wynit » sont exploités par la société
          WYNIT.
        </p>
        <p className={PaddingTop20}>
          L’utilisation du site et de l’application « wynit » implique
          l’acceptation pleine et entière des présentes conditions générales de
          vente et d’utilisation.
        </p>
        <p className={PaddingTop20}>
          Chaque Utilisateur reconnaît avoir pris connaissance des présentes
          conditions générales de vente et d’utilisation et déclare en accepter
          les termes. L’utilisation du site, la création d’un compte Utilisateur
          ou la passation d’une commande en ligne implique l’acceptation sans
          réserve des présentes conditions générales de vente et d’utilisation.
        </p>
        <p className={PaddingTop20}>
          Ces Conditions Générales de Vente et d’Utilisation sont accessibles à
          tout moment sur le site internet https://wynit.fr et prévaudront, le
          cas échéant, sur toute autre version ou tout autre document
          contradictoire.
        </p>
        <p className={PaddingTop20}>
          De nouvelles fonctionnalités et de nouveaux outils seront susceptibles
          d’être ajoutés ultérieurement et seront également assujettis aux
          présentes conditions générales de vente et d’utilisation.
        </p>
        <p className={PaddingTop20}>
          Ces Conditions Générales de Vente et d’utilisation pourront faire
          l’objet de mises à jour et/ou modifications, les conditions
          applicables étant celles en vigueur lors de l’utilisation du site ou à
          la date de passation de la commande par l’Utilisateur.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur reconnaît avoir été informé de cette possibilité de
          modification des présentes conditions et il lui incombe en conséquence
          de consulter régulièrement la version en vigueur des présentes
          conditions générales de vente et d’utilisation.
        </p>
        <p className={PaddingTop20}>
          L’édition ainsi que la sauvegarde de ces conditions relèvent de la
          responsabilité de l’Utilisateur
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 1 – DEFINITIONS</h3>
        <p className={PaddingTop30}>
          Dans les présentes conditions générales de vente et d’utilisation, les
          termes et expressions identifiés par une majuscule ont la
          signification indiquée ci-après, qu’ils soient employés au singulier
          ou au pluriel.
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Etablissement » : désigne tout commerçant, personne physique ou
          personne morale, distribuant des produits et/ou services susceptibles
          d’intéresser une clientèle âgée de 15 à 26 ans.
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Utilisateur » : désigne toute personne âgée de plus de 15 ans
          jusqu’à 26 ans et poursuivant des études secondaires et/ou supérieures
          éligibles aux offres et promotions mis en ligne par la société WYNIT
          sur son application mobile et son site Internet. Pour être éligible,
          les Utilisateurs devront être âgés de moins de 26 ans lors de la
          souscription de l’abonnement, peu important à cet égard qu’ils
          atteignent l’âge de 26 ans pendant l’abonnement.
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Offre de bienvenue » : désigne l’offre offerte par l’Etablissement
          partenaire lors de la première visite ou à l’occasion de la première
          commande de l’Utilisateur au sein de son Etablissement. Cette Offre de
          bienvenue n’est valable que pour les abonnements annuels. Toutefois,
          les Utilisateurs, ayant souscrit une offre d’abonnement mensuel ou une
          offre d’essai gratuite, auront la possibilité de jouer une fois par
          jour pour tenter de gagner une des Offres de bienvenue.
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Offre permanente » : désigne toute offre mise en ligne de manière
          permanente pendant toute la durée du partenariat et de ces éventuels
          renouvellements par un Etablissement partenaire à destination des
          Utilisateurs. Ces Offres permanentes peuvent être utilisées une fois
          par jour.
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Offre éphémère » : désigne toute offre mise en ligne pour une
          durée limitée par un Etablissement partenaire à destination des
          Utilisateurs
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Offre éphémère » : désigne toute offre mise en ligne pour une
          durée limitée par un Etablissement partenaire à destination des
          Utilisateurs
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « Application » : désigne l’application mobile « WYNIT »
          téléchargeable via les boutiques d’applications mobiles (telles que
          notamment Google Play Store, Apple AppStore, Android).
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - « WYNIT » : désigne la société WYNIT, SAS immatriculée au registre
          du commerce et des sociétés de Marseille sous le n°900 300 187 et dont
          le siège social est sis 22 Domaine de Saint Christophe 13011
          MARSEILLE.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 2 – IDENTIFICATION</h3>
        <p className={PaddingTop30}>Editeur du Site: SAS WYNIT</p>
        <p className={PaddingTop20}>
          Siège social: 22 Domaine Saint Christophe – 13011 MARSEILLE
        </p>
        <p className={PaddingTop20}>Hébergeur: 1&1</p>
        <p className={PaddingTop20}>Directeur de publication: Thomas Ardito</p>
        <p className={PaddingTop20}>E-mail: contact@wynit.fr</p>
        <p className={PaddingTop20}>
          Toutes questions concernant les présentes Conditions Générales de
          Vente et d’Utilisation sont à envoyer à l’adresse suivante:
        </p>
        <p>SAS WYNIT</p>
        <p>contact@wynit.fr</p>
        <p>22 Domaine Saint Christophe – 13011 MARSEILLE</p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 3 – PRÉSENTATION GÉNÉRALE DU SERVICE</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          3.1 Objet du service
        </p>
        <p className={PaddingTop20}>
          Le site et l’application « wynit » sont exploités par la société
          WYNIT.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT commercialise par l’intermédiaire de son site
          internet et de son application mobile des abonnements à destination
          des Utilisateurs âgés de plus de 15 ans et de moins de 26 ans à la
          date de souscription de leur abonnement leur permettant de bénéficier
          d’offre de bienvenue, d’offre promotionnelle et de bons plans auprès
          de différents Etablissement partenaires sur toute la France
          métropolitaine.
        </p>
        <p className={PaddingTop20}>
          Les Utilisateurs, souscrivant à un abonnement par l’intermédiaire du
          site www.wynit.fr, bénéficient ainsi d’offre de bienvenue, d’offre
          promotionnelle et de bons plans auprès de l’ensemble des
          Etablissements partenaires sur toute la France métropolitaine.
        </p>
        <p className={PaddingTop20}>
          L’application WYNIT intègre un outil de géolocalisation permettant aux
          Utilisateurs de visualiser les bons plans et offres promotionnelles à
          proximité et de les utiliser.
        </p>
        <p className={PaddingTop20}>
          L'accès et l’utilisation du Site, l’inscription sur le Site et la
          souscription à l’un des services proposés par le Site impliquent
          l’acceptation sans réserve de l'intégralité des dispositions prévues
          aux présentes conditions générales.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur déclare remplir les conditions pour s’inscrire sur la
          plateforme, être majeur et avoir la pleine capacité juridique lui
          permettant de s'engager au titre des présentes Conditions Générales de
          Vente et d’Utilisation ou agir sous la supervision et avec l’accord
          d’une personne titulaire de l’autorité parentale.
        </p>
        <p className={PaddingTop20}>
          En cas de non-respect des présentes Conditions Générales, la société
          WYNIT se réserve le droit d'interrompre de manière temporaire ou
          définitive l'accès à la plate-forme l’Utilisateur concerné
        </p>
        <p className={PaddingTop20}>
          Par ailleurs, l’Utilisateur reconnaît avoir lu la Politique de
          confidentialité disponible à « Politique de confidentialité ».
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          3.2 Fonctionnement du service
        </p>
        <p className={PaddingTop20}>
          Suite à la souscription d’un abonnement, l’Utilisateur aura accès à
          l’ensemble des Offres permanentes et Offres éphémères.
        </p>
        <p className={PaddingTop20}>
          Les Offres de bienvenue ne seront accessibles qu’aux Utilisateurs
          ayant souscrit à une formule d’abonnement annuel. Toutefois, pour les
          autres formules d’abonnement 7 jours ou 30 jours, l’Utilisateur aura
          la possibilité de jouer une fois par jour en actionnant une roulette
          chance lui permettant de gagner l’une des Offres de bienvenue.
        </p>
        <p className={PaddingTop20}>
          Concernant les Offres de bienvenue accessible dans le cadre d’un
          abonnement annuel, chaque Offre de Bienvenue ne pourra être utilisée
          qu’à une seule reprise dans le cadre d’un abonnement annuel.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur, quel que soit sa formule d’abonnement, pourra toutefois
          bénéficier de l’ensemble des Offres permanentes une fois par jour
          ainsi que des offres éphémères selon les conditions fixées par les
          Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          L’accès au Site nécessite que l’Utilisateur dispose de matériel
          informatique (PC, Mac, smartphone, tablette…), d’une connexion
          internet et d’un navigateur web. L’Application Wynit peut être
          téléchargée via les boutiques d’applications mobiles (telles que
          notamment Google Play Store, Apple AppStore, Android) au moyen des
          technologies disponibles, et notamment d'un terminal mobile de type
          smartphone. Tous les coûts afférents à l'accès au Site/ Application et
          à son utilisation, que ce soient les frais matériels, logiciels ou
          d'accès à internet sont exclusivement à la charge de l ’Utilisateur,
          lequel est seul responsable du bon fonctionnement de son équipement
          informatique ainsi que de son accès à Internet.
        </p>
        <p className={PaddingTop20}>
          Le Site et l’Application Wynit sont accessible 24 heures sur 24, 7
          jours sur 7, sous réserve de la survenance d’un cas de force majeure
          et sauf interruption, suspension ou limitation dans le cadre
          d’opérations de maintenance et/ou de mise à jour nécessaires au bon
          fonctionnement du Site et de l’Application ou pour toute autre raison,
          notamment technique.
        </p>
        <p className={PaddingTop20}>
          A cet égard, il est rappelé que la société WYNIT est seulement tenue à
          une obligation de moyens concernant l’accessibilité, le fonctionnement
          et/ou la disponibilité du Site ou de l’Application. La société WYNIT
          se réserve ainsi la possibilité d'interrompre, de suspendre ou de
          limiter l’accès à tout ou partie du Site ou de l’Application,
          notamment en raison de contraintes d’ordre juridique ou technique.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur reconnaît expressément que les interruptions,
          suspensions ou limitations susmentionnées pourront intervenir à tout
          moment, sans avertissement préalablement, et qu’elles n'ouvriront
          droit à aucune obligation, ni indemnisation, au profit de
          l’Utilisateur.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 4 – CONDITIONS D’ACCES AU SERVICE</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          4.1 Inscription en qualité d’Utilisateur
        </p>
        <p className={PaddingTop20}>
          Pour pouvoir s’inscrire, l’Utilisateur doit remplir les conditions
          suivantes:
        </p>
        <p className={PaddingLeft30}>
          - Être âgé de 15 ans au minimum et avoir moins de 26 ans au jour de la
          souscription de la formule d’abonnement, les mineurs s’engageant à
          recueillir préalablement l’accord de leur représentant légal.
        </p>
        <p className={PaddingLeft30}>
          - Disposer d’un numéro de téléphone fonctionnel et personnel, une
          demande de vérification étant envoyée par SMS lors de la création du
          compte.
        </p>
        <p className={PaddingLeft30}>
          - Disposer d’une connexion internet suffisante.
        </p>
        <p className={PaddingTop20}>
          Lorsqu’il utilise les services offerts par la société WYNIT,
          l’Utilisateur s’engage à respecter strictement la réglementation en
          vigueur. A défaut, et si la société WYNIT était informée du
          non-respect de la réglementation en vigueur et des présentes
          conditions générales de vente et d’utilisation, le Compte de
          l’Utilisateur pourra être désactivé et supprimé.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          4.2 Création d’un compte personnel
        </p>
        <p className={PaddingTop20}>
          L’accès au service proposé par la société WYNIT nécessite la création
          d’un compte personnel.
        </p>
        <p className={PaddingTop20}>
          Afin de créer un Compte personnel « Utilisateur » et de souscrire un
          abonnement, l’Utilisateur doit s'inscrire en remplissant le formulaire
          d'inscription présent sur le site internet et renseigner les
          informations suivantes : Civilité, Prénom, Nom de Famille, Adresse,
          Age, Email, mot de passe choisi, numéro de téléphone, photo.
        </p>
        <p className={PaddingTop20}>
          Pour créer un compte, l’Utilisateur doit renseigner tous les
          renseignements obligatoires requis sur le formulaire et qui sont
          mentionné par un astérisque et il doit expressément accepter les
          présentes conditions générales de vente et d’utilisation suivant la
          procédure du clic numérique en cliquant sur le bouton « J’accepte ».
        </p>
        <p className={PaddingTop20}>
          La validation du Compte personnel de l’Utilisateur est formalisée par
          l’envoi d’un SMS au numéro de téléphone renseigné par l’Utilisateur
          dans le formulaire d’inscription.
        </p>
        <p className={PaddingTop20}>
          Les informations qui seront visibles sur le profil sont les suivantes:
        </p>
        <p className={PaddingLeft30}>- Nom de l’Utilisateur.</p>
        <p className={PaddingTop20}>
          En créant son compte, l’Utilisateur garantit que les données qu'il
          communique sont exactes, complètes et conformes à la réalité. La
          responsabilité de l’Utilisateur est susceptible d’être engagée tant
          vis-à-vis de la société WYNIT que des Etablissements partenaires en
          cas de fournitures de données erronées.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur s’engage par ailleurs à informer sans délai la société
          WYNIT de tout changement concernant les données communiquées lors de
          la création de son compte via l’adresse de messagerie suivante :
          contact@wynit.fr
        </p>
        <p className={PaddingTop20}>
          Dans le cas où les informations transmises par l’Utilisateur se
          révéleraient fausses, incomplètes, obsolètes ou ne permettant pas la
          fourniture du Service dans les meilleures conditions, la société WYNIT
          se réserve le droit de procéder à la fermeture du Compte personnel de
          l’Utilisateur et ainsi de lui refuser l’accès au Service, sans préavis
          et sans que cela ne lui ouvre un quelconque droit à indemnité.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur s'engage à ne créer qu'un seul Compte personnel
          correspondant à son identité. L’Utilisateur reste seul responsable de
          tout usage qui pourrait être fait de ses identifiants et mot de passe,
          et de leur confidentialité, ainsi que de toute utilisation de son
          Compte personnel. L’Utilisateur s'engage à informer immédiatement la
          société WYNIT de toute utilisation ou risque d’utilisation frauduleuse
          de son Compte personnel, et de toute atteinte à la confidentialité et
          à la sécurité de ses identifiants et mot de passe à l’adresse
          électronique de contact suivante : contact@wynit.fr.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT ne pourra en aucun cas être tenue responsable de
          toute perte ou vol ou tout dommage résultant de la perte ou du vol ou
          d’une utilisation frauduleuse des données d’identifications de
          l’Utilisateur.
        </p>
        <p className={PaddingTop20}>
          En cas d’oubli de mot de passe, il suffit de cliquer sur « mot de
          passe oublié » et le site envoie à l’adresse e-mail de l’Utilisateur
          un lien permettant de le redéfinir.
        </p>
        <p className={PaddingTop20}>
          Le renseignement de ces données et la validation du compte personnel
          sont indispensables à la fourniture du Service.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          4.3 Procédure de vérification
        </p>
        <p className={PaddingTop20}>
          La société WYNIT peut, à des fins de transparence, d’amélioration de
          la confiance, ou de prévention ou de détection des fraudes, mettre en
          place un système de vérification de certaines des informations
          fournies lors de la création du compte personnel.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 5 – FONCTIONNEMENT DU SERVICE</h3>
        <p className={PaddingTop30}>
          WYNIT est une plateforme permettant aux Utilisateurs ayant souscrit un
          abonnement de bénéficier d’Offres de bienvenue, d’Offres permanentes
          et d’Offres éphémères auprès d’Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Le Service fonctionne selon les modalités suivantes, que les
          Utilisateurs acceptent expressément :
        </p>
        <p className={classNames([PaddingLeft30, PaddingTop10])}>
          - L’Utilisateur créée un compte personnel sur le site et l’application
          Wynit et souscrit un abonnement par l’intermédiaire du Site.
        </p>
        <p className={PaddingLeft30}>
          - Une fois l’abonnement souscrit, l’Utilisateur a accès à l’ensemble
          des Offres permanentes et des Offres éphémères mises en ligne par les
          Etablissements partenaires.
        </p>
        <p className={PaddingLeft30}>
          - L’Utilisateur, ayant souscrit un abonnement annuel, peut utiliser à
          une seule reprise au cours de l’abonnement l’Offre de bienvenue émise
          par chaque Etablissement partenaire.
        </p>
        <p className={PaddingLeft30}>
          - L’Utilisateur peut utiliser une fois par jour l’ensemble des Offres
          permanentes émises par les Etablissements partenaire ainsi que les
          Offres éphémères sous réserve d’en respecter les conditions.
        </p>
        <p className={PaddingLeft30}>
          - Chaque jour, l’Utilisateur, ayant souscrit un abonnement mensuel ou
          un essai gratuit de 7 jours, a la possibilité de jouer en actionnant
          une roulette chance et de gagner des Offres de bienvenue.
        </p>
        <p className={PaddingLeft30}>
          - L’Utilisateur peut rechercher des Offres sur le site ou sur
          l’application au moyen de plusieurs critères de recherche prédéfinis.
        </p>
        <p className={PaddingLeft30}>
          - L’Utilisateur peut se géolocaliser via le site ou l’application
          mobile afin d’identifier les Offres à proximité et éventuellement les
          utiliser.
        </p>
        <p className={PaddingLeft30}>
          - Une fois l’Offre validée, l’Utilisateur reçoit un récapitulatif de
          l’Offre avec le délai pour l’utiliser (délai de 15 minutes).
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          5.1 Etendu du Service
        </p>
        <p className={PaddingTop20}>
          Wynit est une plate-forme proposant des abonnements à des Utilisateurs
          âgés de plus de 15 ans et de moins de 26 ans leur permettant de
          bénéficier d’Offres de bienvenue, d’Offres permanentes et d’Offres
          éphémères auprès d’Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Ces différentes offres sont matérialisées sous la forme d’annonce
          indiquant:
        </p>
        <p className={PaddingLeft30}>
          - L’identité de l’Etablissement partenaire.
        </p>
        <p className={PaddingLeft30}>
          - La localisation de l’Etablissement partenaire.
        </p>
        <p className={PaddingLeft30}>
          - Les différents types d’offres proposées et leur durée de validité
          éventuelle.
        </p>
        <p className={PaddingLeft30}>
          - Les caractéristiques précises de l’Offre.
        </p>
        <p className={PaddingTop20}>
          En tant qu’éditeur du Site et de l’Application Wynit, la société WYNIT
          ne fournit pas les Offres proposées par les Etablissements
          partenaires.
        </p>
        <p className={PaddingTop20}>
          Les Etablissements partenaires sont seuls responsables de leurs Offres
          et des Services qu’ils proposent. Lorsque des Utilisateurs utilisent
          des Offres auprès des Etablissements partenaires, ils concluent un
          contrat directement avec l’Etablissement partenaire concerné. La
          société Wynit n’est ni ne devient partie à un quelconque contrat passé
          entre les Utilisateurs et les Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          La société Wynit ne contrôle ni ne garantit la véracité ou
          l’exactitude de la description des Offres, ni leur légalité.
        </p>
        <p className={PaddingTop20}>
          Afin de promouvoir le Site et l’Application Wynit et d’accroître la
          visibilité des Offres auprès des Utilisateurs potentiels, les Offres
          peuvent être publiées sur d’autres sites Internet, dans des
          applications et des e-mails, ainsi que dans des publicités en ligne et
          hors ligne.
        </p>
        <p className={PaddingTop20}>
          Le Site et l’Application Wynit peuvent contenir des liens vers des
          sites Internet ou des ressources tiers. Ces Services tiers peuvent
          être soumis à des Conditions de Service et à des pratiques en matière
          de protection des données à caractère personnel différentes. La
          société Wynit ne saurait être tenue responsable de la disponibilité ou
          de l’exactitude de ces Services tiers, ou du contenu, des produits ou
          services disponibles auprès de ces Services tiers. Les liens vers ces
          Services tiers ne sauraient être interprétés comme une approbation par
          la société Wynit de ces Services tiers.
        </p>
        <p className={PaddingTop20}>
          La société Wynit n'est pas responsable en cas de pannes ou coupures
          d'Internet ou des infrastructures de télécommunications qui ne
          relèvent pas de son contrôle et qui peuvent conduire à des
          interruptions de l'accessibilité de la plateforme Wynit. La société
          Wynit peut améliorer ou modifier la Plate-forme Wynit et ajouter de
          nouveaux Services à tout moment. La société Wynit informera les
          Utilisateurs de toutes modifications effectuées sur la plateforme
          Wynit, à moins que ces modifications ne soient mineures ou qu'elles
          n'aient pas d'effet matériel sur les obligations contractuelles des
          parties.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          5.2 Utilisation des Offres
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur, qui souscrit un abonnement, peut utiliser pendant toute
          la durée de son abonnement les différentes Offres mises en place par
          les Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Concernant l’Offre de bienvenue mise en place par les Etablissements
          partenaires, celle-ci ne pourra être utilisée qu’une seule fois auprès
          chaque Etablissement partenaire par l’Utilisateur après la
          souscription d’un abonnement annuel.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur aura la possibilité de se géolocaliser afin de
          visualiser les différentes Offres émises par les Etablissements
          partenaires à proximité. L’Utilisateur aura également la possibilité
          de rechercher les offres disponibles au moyen de plusieurs critères de
          recherche prédéfinis.
        </p>
        <p className={PaddingTop20}>
          Pour valider une Offre, il suffit pour l’Utilisateur de cliquer sur
          une des Offres disponibles. Une fenêtre s’ouvrira récapitulant l’Offre
          choisie ainsi que l’identité de l’Etablissement partenaire et sa
          localisation. Pour valider définitivement l’Offre, l’Utilisateur sera
          invité à cliquer sur l’onglet « Utiliser l’Offre ». Une fenêtre
          s’affichera pour confirmer l’utilisation de l’Offre ainsi que le délai
          pour l’utiliser, laquelle sera également envoyée par e-mail, à
          l’adresse indiquée sur le compte de l’Utilisateur. Ce récapitulatif
          devra être présenté par l’Utilisateur auprès de l’Etablissement
          partenaire.
        </p>
        <p className={PaddingTop20}>
          L’utilisation d’un Offre quel qu’elle soit ne peut se faire que par
          l’intermédiaire de l’Application WYNIT.
        </p>
        <p className={PaddingTop20}>
          Une fois l’Offre validée par l’intermédiaire de l’Application,
          l’Utilisateur recevra un récapitulatif de l’Offre avec le délai pour
          l’utiliser (délai de 15 minutes).
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          5.3 Géolocalisation du Service
        </p>
        <p className={PaddingTop20}>
          L’utilisation de la fonctionnalité de géolocalisation du Site ou de
          l’Application nécessite le consentement préalable exprès de
          l’Utilisateur à être géolocalisé. Pour cela, l’Utilisateur devra
          activer, s’il le souhaite, la fonction de géolocalisation directement
          dans les réglages de son ordinateur ou de son terminal mobile et
          accepter que l’Application puisse y avoir recours. Cette
          fonctionnalité peut, à tout moment, et sans frais être désactivée ou
          activée par l’Utilisateur lui-même
        </p>
        <p className={PaddingTop20}>
          Grâce à l’acceptation de la fonction de géolocalisation, l’Utilisateur
          accèdera plus facilement aux Offres disponible dans sa zone
          géographique et les Etablissements partenaires pourront être
          matérialisés via l’Application.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 6 – OBLIGATIONS DES UTILISATEURS</h3>
        <p className={PaddingTop20}>
          L’Utilisateur s’engage à respecter les présentes conditions générales
          de ventes et d’utilisation ainsi que la politique de confidentialité.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur s’engage à respecter les présentes conditions générales
          de ventes et d’utilisation ainsi que la politique de confidentialité.
        </p>
        <p className={PaddingTop20}>
          De manière générale, et sans que cette liste puisse être considérée
          comme exhaustive, l’Utilisateur s’interdit:
        </p>
        <p className={PaddingLeft30}>
          - De fournir des informations incomplètes, erronées ou inexactes, ou
          dont il n’est pas titulaire ou détenteur légitime ;
        </p>
        <p className={PaddingLeft30}>
          - D’utiliser le Site dans des conditions ou selon des modalités qui
          entraîneraient la violation par lui d’autres dispositions ou
          engagements contractuels ou constitutives d’une fraude ;
        </p>
        <p className={PaddingLeft30}>
          - D’ouvrir plus d’un Compte sur la Plateforme ou d’ouvrir un Compte au
          nom d’un tiers ;
        </p>
        <p className={PaddingLeft30}>
          - De télécharger, reproduire, enregistrer, copier, compiler,
          décompiler, partager avec un tiers ou exploiter, par tout procédé ou
          moyen, le contenu du Site et les résultats de son utilisation ;
        </p>
        <p className={PaddingLeft30}>
          - D’entraver ou de perturber le fonctionnement du Site ;
        </p>
        <p className={PaddingLeft30}>
          - De déposer, diffuser ou utiliser sur ou depuis le Site des contenus
          portant atteinte aux droits de tiers, et en particulier à des droits
          de propriété intellectuelle (marque, droit d’auteur, modèles en
          particulier), aux droits de personne (notamment usurpation d’identité,
          diffamation, insultes, injures, dénigrement, harcèlement, etc.), au
          respect de la vie privée (en ce compris le droit à l’image), à l’ordre
          public et aux bonnes mœurs (notamment apologie des crimes contre
          l’humanité, incitation à la haine raciale, atteinte à la dignité
          humaine, etc.) et, plus généralement, à la réglementation applicable
          en vigueur.
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur garantit la société WYNIT contre toute réclamation,
          action et/ou revendication d’autres Utilisateurs ou de tiers
          concernant son utilisation du Site internet et de l’application WYNIT.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 7 – DISPOSITIONS FINANCIERES</h3>
        <p className={PaddingTop20}>
          L’accès et l’inscription à la Plateforme sont gratuits pour les
          Utilisateurs. En revanche, la souscription d’un abonnement est payante
          dans les conditions décrites ci-dessous.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          7.1 Prix de l‘abonnement
        </p>
        <p className={PaddingTop20}>
          Le prix de l’abonnement est fonction de sa durée. Deux formules sont
          disponibles avec des abonnements pour 30 jours ou 1 an selon les
          tarifs cidessous:
        </p>
        <p className={PaddingLeft30}>
          - abonnement de 30 jours au prix est de 5 € TTC.
        </p>
        <p className={PaddingLeft30}>
          - abonnement de 1 an au prix est de 30 € TTC.
        </p>
        <p className={PaddingTop20}>
          Chaque Utilisateur pourra bénéficier de 7 jours d’essai gratuitement.
          Ces 7 jours d’essai ne pourront être utilisés qu’une seule fois par
          l’Utilisateur afin de tester le service (même adresse IP (Internet
          Protocol) et/ou même adresse de courrier électronique et/ou même
          numéro de téléphone).
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          7.2 Modalité de paiement
        </p>
        <p className={PaddingTop20}>
          L’achat de l’abonnement s’effectue en ligne par l’Utilisateur.
        </p>
        <p className={PaddingTop20}>
          Le paiement s'effectue en ligne sur le site, par carte bancaire. Les
          cartes bancaires acceptées sont les cartes portant les sigles CB, VISA
          ou EUROCARD/ MASTERCARD.
        </p>
        <p className={PaddingTop20}>
          Le paiement s'effectue dans un espace sécurisé aux normes SSL assurant
          la confidentialité et la sécurité des transactions bancaires.
        </p>
        <p className={PaddingTop20}>
          Aucune donnée bancaire ne sera visible ou ne sera stockés par la
          société WYNIT. Ces données sont collectées et traitées uniquement par
          le biais du partenaire bancaire de la société WYNIT, dans le respect
          de sa politique de confidentialité. Pour toutes informations
          complémentaires, l’Utilisateur est invité à se reporter à la politique
          de confidentialité, accessible sur le site internet.
        </p>
        <p className={PaddingTop20}>
          La souscription d’un abonnement par l’Utilisateur ne sera considérée
          comme effectif que lorsque les centres de paiement bancaires concernés
          auront donné leur accord. En cas de refus desdits centres, la commande
          sera automatiquement annulée.
        </p>
        <p className={PaddingTop20}>
          Le règlement du prix des abonnements par les Utilisateurs s’effectue
          en ligne auprès de la société WYNIT.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          7.3 Modification du prix de l’abonnement
        </p>
        <p className={PaddingTop20}>
          La société WYNIT se réserve le droit de modifier le prix de chacune
          des formules d’abonnement. La société WYNIT informera chaque
          Utilisateur par courrier électronique à l’adresse renseignée par
          l’Utilisateur concerné lors de son inscription sur le Site et
          l’Application de toute hausse de tarif au minimum 15 (quinze) jours
          avant sa prise d’effet. Dans une telle hypothèse, l’Utilisateur
          n’acceptant pas cette hausse de tarif pourra résilier son abonnement
          dans les conditions indiquées à l’article 9.4 ci-après, cette
          résiliation prenant effet à la prochaine échéance d’abonnement. A
          défaut de résiliation par l’Utilisateur, la nouvelle tarification sera
          dès lors applicable à compter de la prochaine échéance d’abonnement
          suivant la prise d’effet de la nouvelle tarification.
        </p>
        <p className={PaddingTop20}>
          Il est rappelé que les frais de connexion et de communication
          (Internet et Internet mobile) liés à l’utilisation du Site et de
          l’Application restent à la charge de l’Utilisateur.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 8 – ABSENCE DE DROIT DE RETRACTATION</h3>
        <p className={PaddingTop20}>
          En acceptant les présentes conditions générales, l’Utilisateur accepte
          expressément que l’abonnement prenne effet immédiatement avant
          l’expiration du délai de rétractation dès la confirmation du paiement
          de l’abonnement. En conséquence, l’Utilisateur renonce expressément à
          son droit de rétraction, conformément aux dispositions de l’article
          L.221-21-8 du Code de la consommation.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 9 – DUREE – RECONDUCTION - RESILIATION – SUSPENSION</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          9.1 Durée de l’abonnement
        </p>
        <p className={PaddingTop20}>
          La société WYNIT propose à l’Utilisateur différentes formules
          d’abonnement.
        </p>
        <p className={PaddingTop20}>
          Les présentes Conditions Générales sont conclues pour toute la durée
          de l’abonnement choisie par l’Utilisateur ainsi que pour toute la
          durée des reconductions éventuelles.
        </p>
        <p className={PaddingTop20}>
          Il se peut également que des offres d’essai ou de découverte des
          Services offert dans le cadre d’un abonnement soient proposées sur le
          Site ou l’Application de manière temporaire. Sauf indication
          contraire, ces offres d’essai ou de découverte seront soumises aux
          présentes CGU-CGV et seront limitées à une seule inscription (même
          adresse IP (Internet Protocol) et/ou même adresse de courrier
          électronique et/ou même numéro de téléphone) quel que soit l’offre
          d’essai ou de découverte.
        </p>
        <p className={PaddingTop20}>
          En tout état de cause, chaque Utilisateur pourra mettre fin, à tout
          moment, à sa participation au Site et à l’Application « Wynit » en
          cliquant sur l’onglet « supprimer mon compte » de son Compte
          personnel. Si la résiliation intervient alors que l’abonnement est
          toujours en cours, aucun remboursement ne pourra intervenir et la
          résiliation sera effective au terme de la période d’abonnement en
          cours.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          9.2 Résiliation de l’abonnement
        </p>
        <p className={PaddingTop20}>
          L’Utilisateur peut résilier son abonnement à partir de son compte sur
          le Site en cliquant sur la rubrique « Résilier mon abonnement ». La
          prise en compte de la résiliation sera effective au terme de la
          période d’abonnement en cours sous réserve que sa demande ait été
          envoyée au moins 48 (quarante-huit) heures avant son terme, et jusqu’à
          la date et heure de fin telle qu’indiquée sur le compte de
          l’Utilisateur pour une offre d’essai ou de découverte, sauf indication
          contraire sur le Site.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          9.3 Avertissement - Suspension du compte - Résiliation pour faute
        </p>
        <p className={PaddingTop20}>
          Afin de préserver l’image du Site et de l’application et sans
          préjudice des dommages et intérêts éventuels qu’elle serait en droit
          de réclamer, la société WYNIT se réserve le droit de donner un
          avertissement à un Utilisateur, de suspendre temporairement son
          inscription et son compte utilisateur et/ou de résilier les présentes
          Conditions générales d’utilisation et de clôturer le compte
          Utilisateur, de plein droit, avec effet immédiat, dans les cas
          suivants:
        </p>
        <p className={PaddingLeft30}>
          - Non-respect des présentes conditions générales, et notamment
          fourniture de données fausses, erronées ou inexactes.
        </p>
        <p className={PaddingLeft30}>
          - Non-respect des lois et de la réglementation applicable.
        </p>
        <p className={PaddingLeft30}>
          - Diffusion de commentaires et/ou d’images diffamatoires ou injurieux
          ou contraire aux lois et aux règlements.
        </p>
        <p className={PaddingTop20}>
          Si la société WYNIT est avisée d’un tel manquement imputable à un
          Utilisateur, elle lui adressera immédiatement un courriel l’invitant à
          remédier sans délai au manquement constaté. En fonction de la gravité
          du manquement imputable à l’Utilisateur, la société WYNIT pourra
          décider de suspendre temporairement et/ou définitivement son compte.
        </p>
        <p className={PaddingTop20}>
          A défaut pour l’Utilisateur d’avoir remédié au manquement signalé dans
          un délai de 30 jours suivant la réception de la demande formulée par
          la société WYNIT, cette dernière pourra résilier de plein droit et
          sans préavis le compte de l’Utilisateur, sans que ce dernier ne puisse
          prétendre à aucune indemnité du fait de cette résiliation.
        </p>
        <p className={PaddingTop20}>
          En fonction de la gravité du manquement signalé ou en cas de
          manquements répétés de l’Utilisateur à ses obligations, la société
          WYNIT pourra résilier de plein droit et sans préavis le compte de
          l’Utilisateur, sans que ce dernier ne puisse prétendre à aucune
          indemnité du fait de cette résiliation.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          9.4 Conséquence de la résiliation du Compte
        </p>
        <p className={PaddingTop20}>
          En cas de résiliation, pour quelque motif que ce soit, le compte de
          l’Utilisateur sera désactivé et son profil sera immédiatement retiré
          du Site et de l’Application.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 10 – RESPONSABILITE</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          10.1 Responsabilité des Utilisateurs
        </p>
        <p className={PaddingTop20}>
          Chaque utilisateur est responsable des informations qu’il fournit via
          la Plateforme et de l’utilisation qu’il fait du Service.
        </p>
        <p className={PaddingTop20}>
          Dans le cadre de l’utilisation d’un Offre à la suite d’un abonnement,
          les Utilisateurs concluent un contrat directement avec les
          Etablissements partenaires, contrat auquel la société WYNIT n’est pas
          partie. En conséquence, les Utilisateurs et les Etablissement
          partenaires agissent sous leur seule et entière responsabilité,
          notamment conformément aux dispositions du code civil relatives au
          droit des obligations et à la responsabilité civile contractuelle.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          10.2 Responsabilité de la société WYNIT
        </p>
        <p className={PaddingTop20}>
          Le site et l’application WYNIT permettent aux Utilisateurs de
          souscrire des abonnements afin de bénéficier d’Offres préférentielles
          au sein d’Etablissements partenaires.
        </p>
        <p className={PaddingTop20}>
          Les Etablissements partenaires sont seuls responsables des offres et
          produits et services qu’ils proposent aux Utilisateurs. La société
          WYNIT ne contrôle ni ne garantit la véracité ou l’exactitude des
          descriptions des Offres, ni leur adéquation ou leur légalité et sa
          responsabilité ne saurait donc être engagée à ce titre. Il appartient
          aux Utilisateurs de faire remonter à la société WYNIT les éventuelles
          difficultés rencontrées lors de l’Utilisation d’une Offre au sein d’un
          Etablissement partenaire.
        </p>
        <p className={PaddingTop20}>
          En tant qu’éditeur du site et de l’application, la société WYNIT n’est
          ni le rédacteur des Offres émises par les Etablissements partenaires
          et ne fournit pas les Services et Produits proposés par les
          Etablissements partenaires. En utilisant le site et l’application
          WYNIT et en acceptant les présentes Conditions générales de Vente et
          d’Utilisation, les Utilisateurs reconnaissent que la société WYNIT
          n’est partie à aucun accord conclu entre eux en vue de la réalisation
          des Offres.
        </p>
        <p className={PaddingTop20}>
          En sa qualité d’intermédiaire, la société WYNIT ne saurait voir sa
          responsabilité engagée au titre de la réalisation d’une Offre, et
          notamment du fait :
        </p>
        <p className={PaddingLeft30}>
          - D’informations erronées communiquées par l’Etablissement partenaire,
          dans la rédaction de son Offre, ou par tout autre moyen;
        </p>
        <p className={PaddingLeft30}>
          - De l’annulation ou de la modification d’une Offre
        </p>
        <p className={PaddingLeft30}>
          - Du comportement et/ou des commentaires d’un Utilisateur pendant,
          avant, ou après l’utilisation d’une Offre.
        </p>
        <p className={PaddingTop20}>
          Afin de promouvoir le Site et l’Application WYNIT et d’accroître la
          visibilité des Offres des Etablissements partenaires auprès
          d’Utilisateurs potentiels, les Offres permanentes, Offres de bienvenue
          et Offres éphémères peuvent être publiées sur d’autres sites Internet,
          dans des applications et des e-mails, dans des publicités en ligne et
          hors ligne ainsi que sur les réseaux sociaux.
        </p>
        <p className={PaddingTop20}>
          Afin de promouvoir le Site et l’Application WYNIT et d’accroître la
          visibilité des Offres des Etablissements partenaires auprès
          d’Utilisateurs potentiels, les Offres permanentes, Offres de bienvenue
          et Offres éphémères peuvent être publiées sur d’autres sites Internet,
          dans des applications et des e-mails, dans des publicités en ligne et
          hors ligne ainsi que sur les réseaux sociaux.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT n'est pas responsable en cas de pannes ou coupures
          d'Internet ou des infrastructures de télécommunications qui ne
          relèvent pas de son contrôle et qui peuvent conduire à des
          interruptions de l'accessibilité du Site et de l’Application. La
          société WYNIT s’efforcera, dans la mesure du possible, de maintenir le
          Site et l’Application accessible 7 jours sur 7 et 24 heures sur 24.
          Néanmoins, l’accès au Site et à l’Application pourra être
          temporairement suspendu, sans préavis, en raison d’opérations
          techniques de maintenance, de migration, de mises à jour ou en raison
          de pannes ou de contraintes liées au fonctionnement des réseaux. En
          outre, la société WYNIT se réserve le droit de modifier ou
          d’interrompre, à sa seule discrétion, de manière temporaire ou
          permanente, tout ou partie de l’accès au Site ou à l’Application ainsi
          que ses fonctionnalités.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT s'engage à faire ses meilleurs efforts pour sécuriser
          son site. Néanmoins, aucun site n'étant inviolable, sa responsabilité
          ne pourra être mise en cause si des données indésirables sont
          importées et installées sur son site à son insu, ou si à l'inverse,
          les données transmises par l’Utilisateur pour gérer sa commande sont
          détournées pour être utilisées illicitement par des tiers. En outre,
          il appartient aux Utilisateurs du Site et de l’Application de prendre
          toutes mesures appropriées de nature à protéger ses propres données et
          logiciels de la contamination par d'éventuels "virus" informatiques et
          la société WYNIT ne pourra en aucun cas être tenu pour responsable
          d'éventuels dommages susceptibles d'en découler, tels que pertes de
          données ou détérioration d'équipement informatique.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 11 – DONNEES PERSONNELLES</h3>
        <p className={PaddingTop20}>
          L’inscription sur le Site et l’Application WYNIT et l’utilisation du
          service implique la collecte et le traitement de données à caractère
          personnel concernant les Utilisateurs. La collecte et le traitement de
          ces données à caractère personnel, ainsi que les droits dont vous
          disposez, sont explicités au sein de notre politique de
          confidentialité laquelle est librement consultable et téléchargeable
          sur le site internet.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 12 – PROPRIETE INTELLECTUELLE</h3>
        <p className={PaddingTop20}>
          Le Site et l’Application WYNIT constitue une œuvre protégée par la
          législation en vigueur, et notamment les articles L. 122-1 et suivants
          du Code de la propriété intellectuelle. Les textes, photographies,
          images animées ou non, savoir-faire, dessins, graphismes et tout autre
          élément composant le Site et l’Application sont la propriété de la
          société WYNIT ou de tiers ayant autorisé la société WYNIT à les
          utiliser.
        </p>
        <p className={PaddingTop20}>
          À ce titre et conformément aux dispositions du Code de la propriété
          intellectuelle, toute reproduction, représentation, modification ou
          adaptation totale ou partielle du Site et/ou de tout ou partie des
          éléments se trouvant sur le Site et la web application ou qui y sont
          incorporés est strictement interdite et constitutive du délit de
          contrefaçon pouvant engager la responsabilité civile et pénal
        </p>
        <p className={PaddingTop20}>
          L’utilisation du Site et/ou de l’Application ne confère en aucune
          façon un droit de propriété ou un droit de propriété intellectuelle
          sur le Site et l’Application et/ou les élémentsse trouvant sur le Site
          et l’Application à l’exception d’un droit personnel d’accès, non
          exclusif, limité exclusivement à l’utilisation du Site et des éléments
          se trouvant sur le Site et de la web application conformément aux
          finalités définies dans le cadre des présentes conditions générales.
        </p>
        <p className={PaddingTop20}>
          Les marques et logos WYNIT, ainsi que les marques et logos des
          Etablissements partenaires sont des marques déposées. Toute
          reproduction totale ou partielle de ces marques et/ou logos sans
          l'autorisation préalable et écrite de la société WYNIT est interdite.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT est producteur des bases de données. En conséquence,
          toute extraction et/ou réutilisation de la ou des bases de données au
          sens des articles L 342-1 et L 342-2 du code de la propriété
          intellectuelle est interdite.
        </p>
        <p className={PaddingTop20}>
          La société WYNIT se réserve la possibilité de saisir toutes voies de
          droit à l'encontre des personnes qui n'auraient pas respecté les
          interdictions contenues dans le présent article.
        </p>
        <p className={PaddingTop20}>
          Tous les droits de propriété intellectuelle (tels que notamment droits
          d'auteur, droits voisins, droits des marques, droits des producteurs
          de bases de données) portant tant sur la structure que sur les
          contenus du Site et de la web applications et notamment les images,
          sons, vidéos, photographies, logos, marques, éléments graphiques,
          textuels, visuels, outils, logiciels, documents, données, etc.) sont
          réservés. Ces éléments sont la propriété de la société WYNIT et sont
          mis à disposition des Utilisateurs pour la seule utilisation du Site
          et de l’Application WYNIT, et dans le cadre d'une utilisation normale
          de ses fonctionnalités. Les Utilisateurs s'engagent à ne modifier en
          aucune manière ces éléments. Toute utilisation non expressément
          autorisée des éléments du Site Internet et/ou de l’Application
          entraîne une violation des droits de propriété intellectuelle et
          constitue une contrefaçon. Elle peut aussi entraîner une violation des
          droits à l'image, droits des personnes ou de tous autres droits et
          réglementations en vigueur. Elle peut donc engager la responsabilité
          civile et/ou pénale de son auteur.
        </p>
      </div>
      <div className={PaddingTop50}>
        <h3>ARTICLE 13 – DISPOSITIONS GENERALES</h3>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          13.1 Archivage et convention de preuve
        </p>
        <p className={PaddingTop20}>
          Le "clic" de l’Utilisateur effectué au titre de l'acceptation des
          présentes conditions générales d’utilisation et de vente, mais
          également au titre du bon de commande, constitue une signature
          électronique qui a, entre les parties, la même valeur qu'une signature
          manuscrite.
        </p>
        <p className={PaddingTop20}>
          Les registres informatisés conservés dans les systèmes informatiques
          de la société WYNIT, seront conservés dans des conditions raisonnables
          de sécurité et considérés comme les preuves des communications, des
          commandes et des paiements intervenus entre les parties.
        </p>
        <p className={PaddingTop20}>
          L'archivage des commandes et des factures est effectué sur un support
          fiable et durable pouvant être produit à titre de preuve.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          13.2 Non validité partielle
        </p>
        <p className={PaddingTop20}>
          Si l'une quelconque des dispositions des présentes conditions
          générales de vente et d’utilisation est déclarée nulle ou sans objet
          au regard d'une disposition législative ou réglementaire en vigueur
          et/ou d’une décision de justice ayant autorité de la chose jugée, elle
          sera réputée non écrite et n'entraînera pas la nullité des autres
          dispositions.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          13.3 Tolérances
        </p>
        <p className={PaddingTop20}>
          Le fait pour l'une des Parties de ne pas se prévaloir d'un manquement
          par l'autre partie à l'une quelconque des obligations visées dans les
          présentes Conditions générales de Vente et d’Utilisation ne saurait
          être interprété pour l'avenir comme une renonciation à l'obligation en
          cause.
        </p>
        <p className={classNames([PaddingTop30, SectionUnderline])}>
          13.4 Loi applicable - Litige
        </p>
        <p className={PaddingTop20}>
          Les présentes conditions générales de vente et d’utilisation sont
          exécutées et interprétées en application du droit français.
        </p>
        <p className={PaddingTop20}>
          En cas de litige, l'Utilisateur s'adressera par priorité à la société
          WYNIT en vue d'un règlement amiable du différend.
        </p>
        <p className={PaddingTop20}>
          Ainsi, toute réclamation relative à l’application des présentes
          conditions générales devra être adressée à l’adresse suivante
          contact@wynit.fr ou à l’adresse postale suivante 22 Domaine Saint
          Christophe – 13011 MARSEILLE, afin qu’elle puisse être traitée dans
          les meilleurs délais.
        </p>
        <p style={{ color: "red" }} className={PaddingTop20}>
          En cas d’échec de la réclamation soumise dans les conditions ci-dessus
          décrites et conformément aux dispositions du Code de la consommation,
          l’Utilisateur a le droit de recourir gratuitement au service de
          médiation proposé par la société WYNIT. Le médiateur « droit de la
          consommation » ainsi proposé est:
        </p>
        <p style={{ color: "red" }} className={PaddingTop20}>
          Ce dispositif de médiation peut être joint par:
        </p>
        <p style={{ color: "red" }} className={PaddingLeft30}>
          - voie électronique :
        </p>
        <p style={{ color: "red" }} className={PaddingLeft30}>
          - ou par voie postale :
        </p>
        <p style={{ color: "red" }} className={PaddingTop20}>
          Par ailleurs et conformément à l’article 14 du règlement (UE) n°
          524/2013 du 21 mai 2013, l’Utilisateur dispose d’un accès à la
          plateforme européenne de règlement en ligne des litiges de
          consommation accessible à l’adresse suivante:
        </p>
        <p>
          https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR
        </p>
      </div>
    </div>
  )
}

export default CGVCGU
